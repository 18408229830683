<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('employee.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('employee.pageTitle'),
                        href: '/admin/employees'
                    },
                    {
                        text: $t('create'),
                        active: true
                    }
                ]"
            />
            <div class="demo-spin-article">
                <div class="row">
                    <div class="col-md-3 tw-mb-5">
                        <profile
                            v-model="model"
                            :validate="errors"
                            class="tw-h-full"
                            ref="profile"
                        />
                    </div>
                    <div class="col-md-9 tw-mb-5">
                        <ts-panel>
                            <ts-panel-wrapper>
                                <form
                                    class="form-horizontal form-horizontal-text-right"
                                >
                                    <details-template
                                        v-model="model"
                                        :validation="errors"
                                        ref="detial"
                                    />
                                    <Tabs v-model="tabValue">
                                        <TabPane
                                            label="DOCUMENTS"
                                            name="documents"
                                        >
                                            <document-template
                                                v-if="tabValue == 'documents'"
                                                v-model="model"
                                                :validation="errors"
                                            />
                                        </TabPane>
                                        <TabPane
                                            label="EDUCATION HISTORY"
                                            name="education_history"
                                        >
                                            <education-history
                                                v-if="
                                                    tabValue ==
                                                        'education_history'
                                                "
                                                v-model="model"
                                                :validation="errors"
                                            />
                                        </TabPane>
                                        <TabPane
                                            label="EMPLOYMENT HISTORY"
                                            name="employment_history"
                                        >
                                            <employment-history
                                                v-if="
                                                    tabValue ==
                                                        'employment_history'
                                                "
                                                v-model="model"
                                                :validation="errors"
                                            />
                                        </TabPane>
                                        <TabPane
                                            label="GUARANTOR INFO"
                                            name="quarantor_info"
                                        >
                                            <quarantor-info
                                                v-if="
                                                    tabValue == 'quarantor_info'
                                                "
                                                v-model="model"
                                                :validation="errors"
                                            />
                                        </TabPane>
                                    </Tabs>
                                    <payroll-template
                                        v-model="model"
                                        :validation="errors"
                                    />
                                    <attach-file
                                        v-model="model"
                                        :validation="errors"
                                    />
                                </form>
                            </ts-panel-wrapper>
                        </ts-panel>
                    </div>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'employee' })"
                class="btn-gray"
            >
                {{ $t("cancel") }}</ts-button
            >
            <ts-button
                :waiting="waiting"
                @click.prevent="onUpdate"
                color="primary"
            >
                {{ $t("update") }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import Profile from "./components/profile.vue";
import DocumentTemplate from "./components/document.vue";
import EducationHistory from "./components/education-history.vue";
import EmploymentHistory from "./components/employment-history.vue";
import DetailsTemplate from "./components/detail";
import PayrollTemplate from "./components/payroll";
import QuarantorInfo from "./components/quarantor-info.vue";
import AttachFile from "./components/attach-file.vue";
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import moment from "moment";
import { isEmpty } from "lodash";

export default {
    name: "employeeEdit",
    components: {
        Profile,
        DocumentTemplate,
        EducationHistory,
        EmploymentHistory,
        DetailsTemplate,
        AttachFile,
        PayrollTemplate,
        QuarantorInfo
    },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            errors: new Errors(),
            tabValue: "documents",
            model: {
                employee_name_kh: null,
                employee_name_en: null,
                sex: null,
                phone: null,
                card_id: null,
                email: null,
                address: null,
                photo: null,
                position_id: null,
                hire_date: moment().format("DD-MM-YYYY"),
                probation_end_date: moment()
                    .add(3, "M")
                    .format("DD-MM-YYYY"),
                status_id: null,
                status_effective_date: null,
                shift_id: null,
                pc_date_id: null,
                payment_type_id: null,
                is_tax_responsed_by_company: false,
                is_spouse_dependant: false,
                num_child_dependant: 0,
                nssf_number: null,
                ssn_no: null,
                ssn_issued_date: null,
                ssn_expiry_date: null,
                ssn_issued_at: null,
                nationality_id: null,
                date_of_birth: null,
                emp_type_id: null,
                status_reason_id: null,
                fp_serial_card: null,
                branch_id: null,
                num_of_payroll: 1,
                province_code: null,
                disctrict_code: null,
                commune_code: null,
                village_code: null,
                is_employer: false,
                is_audit: false,
                details: [],
                education_histories: [],
                employment_histories: [],
                attach_file_list: [],
                employment_guarantor: [],
                is_sm: false,
                line_manager1: null,
                line_manager2: null
            }
        };
    },
    computed: {
        ...mapState("humanResource/employee", ["edit_data"])
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    methods: {
        ...mapActions("humanResource/employee", [
            "getPosition",
            "getStatus",
            "getStatusReason",
            "getShift",
            "getPcDate",
            "getPaymentType",
            "getNationality",
            "find",
            "getLineManager"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getPosition();
            await this.getStatus();
            await this.getStatusReason();
            await this.getShift();
            await this.getPcDate();
            await this.getPaymentType();
            await this.getNationality();
            await this.find(this.$route.params.id).catch(error => {
                this.notice({ type: "error", text: error.message });
            });
            this.setEditData();
            this.$refs.profile.setEditData();
            this.$refs.detial.fetchBranch();
            this.loading = false;
        },
        photoUpload() {
            if (
                this.model.photo instanceof File ||
                this.model.photo instanceof Blob
            ) {
                let formData = new FormData();
                formData.append("photo", this.model.photo);
                return this.$store.dispatch(
                    "humanResource/employee/photoUpload",
                    formData
                );
            }

            return this.model.photo;
        },
        async onUpdate() {
            this.errors = new Errors();
            this.waiting = true;
            let photo_path = await this.photoUpload();
            this.$store
                .dispatch("humanResource/employee/update", {
                    id: this.model.employee_id,
                    data: Object.assign({}, this.model, {
                        photo: photo_path
                    })
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$router.push({ name: "employee" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.employee_id = this.edit_data.employee_id;
                this.model.employee_name_kh = this.edit_data.employee_name_kh;
                this.model.employee_name_en = this.edit_data.employee_name_en;
                this.model.sex = this.edit_data.sex;
                this.model.phone = this.edit_data.phone;
                this.model.card_id = this.edit_data.card_id;
                this.model.email = this.edit_data.email;
                this.model.address = this.edit_data.address;
                this.model.photo = this.edit_data.photo;
                this.model.position_id = this.edit_data.position_id;
                this.model.hire_date = this.edit_data.hire_date;
                this.model.probation_end_date = this.edit_data.probation_end_date;
                this.model.status_id = this.edit_data.status_id;
                this.model.status_effective_date = this.edit_data.status_effective_date;
                this.model.shift_id = this.edit_data.shift_id;
                this.model.pc_date_id = this.edit_data.pc_date_id;
                this.model.payment_type_id = this.edit_data.payment_type_id;
                this.model.is_tax_responsed_by_company = this.edit_data.is_tax_responsed_by_company;
                this.model.is_spouse_dependant = this.edit_data.is_spouse_dependant;
                this.model.num_child_dependant = this.edit_data.num_child_dependant;
                this.model.nssf_number = this.edit_data.nssf_number;
                this.model.ssn_no = this.edit_data.ssn_no;
                this.model.ssn_issued_date = this.edit_data.ssn_issued_date;
                this.model.ssn_expiry_date = this.edit_data.ssn_expiry_date;
                this.model.ssn_issued_at = this.edit_data.ssn_issued_at;
                this.model.nationality_id = this.edit_data.nationality_id;
                this.model.date_of_birth = this.edit_data.date_of_birth;
                this.model.emp_type_id = this.edit_data.emp_type_id;
                this.model.fp_serial_card = this.edit_data.fp_serial_card;
                this.model.status_reason_id = this.edit_data.status_reason_id
                    ? this.edit_data.status_reason_id
                    : null;
                this.model.num_of_payroll = this.edit_data.num_of_payroll;
                this.model.is_employer = this.edit_data.is_employer;
                this.model.is_audit = this.edit_data.is_audit;
                this.model.is_sm = this.edit_data.is_sm;
                this.model.branch_id = this.edit_data.branch_id;
                this.edit_data.details.forEach(detail => {
                    this.model.details.push({
                        document_type_id: detail.document_type_id,
                        card_number: detail.card_number,
                        issued_date: detail.issued_date,
                        issued_at: detail.issued_at,
                        expiry_date: detail.expiry_date
                    });
                });
                this.edit_data.education_histories.forEach(education => {
                    this.model.education_histories.push({
                        year: education.year,
                        degree: education.degree,
                        institution_name: education.institution_name,
                        address: education.address
                    });
                });
                this.edit_data.employment_histories.forEach(employment => {
                    this.model.employment_histories.push({
                        year: employment.year,
                        employer_name: employment.employer_name,
                        industry: employment.industry,
                        position: employment.position,
                        address: employment.address
                    });
                });
                this.edit_data.contracts.forEach(guarantor => {
                    this.model.employment_guarantor.push({
                        contract_start_date: guarantor.contract_start_date,
                        contract_period: guarantor.contract_period,
                        contract_expire_date: guarantor.contract_expire_date,
                        guarantor_name: guarantor.guarantor_name,
                        guarantor_sex: guarantor.guarantor_sex,
                        phone_number: guarantor.phone_number,
                        guarantor_nid_number: guarantor.guarantor_nid_number,
                        nationality_name_en: guarantor.nationality_name_en,
                        address:
                            (guarantor.grt_vil_name_en
                                ? guarantor.grt_vil_name_en
                                : "") +
                            "," +
                            (guarantor.grt_com_name_en
                                ? guarantor.grt_com_name_en
                                : "") +
                            "," +
                            (guarantor.grt_dis_name_en
                                ? guarantor.grt_dis_name_en
                                : "") +
                            "," +
                            (guarantor.grt_pro_name_en
                                ? guarantor.grt_pro_name_en
                                : "")
                    });
                });
            }
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "EMPLOYEE",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        this.$store.commit("humanResource/employee/RESET_STATE");
        next();
    }
};
</script>
